import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Environment } from 'src/environments/environment';
import { RsfConfigClient, RsfConfigFactory } from '@rsf/rsf-angular-base/dist/config.service';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { APP_BASE_HREF, LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from './constant/constant';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpErrorHandler } from './shared/services/http-error-handler.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule, HttpHeaders } from '@angular/common/http';
import { CommonService } from './shared/services/common.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, viLocale } from 'ngx-bootstrap/locale';
import { ApiConstant } from './constant/api.constant';
import ENV from 'src/environments/env';
defineLocale('vi', viLocale);
defineLocale('en', enGbLocale);

declare global {
  interface Window {
    _: any;
  }
}

const AppInitializerFactory = (translate: TranslateService, injector: Injector, localeService: BsLocaleService, http: HttpClient) => {
  return async () => {
    Promise.all([
      await initializeApp(),
      await locationInitialized(translate, injector, localeService),
      await loadConfig(http)
    ]);
  };
};

const initializeApp = (): Promise<any> => {
  // Do some asynchronous stuff
  return RsfConfigClient.toPromise()
    .then(() => {
      const environment = RsfConfigFactory.getEnv() as Environment;
      console.log(`--- Starting environment ---`, environment);
    })
    .catch((error: any) => {
      console.error(error);
    });
};

const appBaseHref = () => {
  const environment = RsfConfigFactory.getEnv() as Environment;
  return environment.appBaseHref;
};

const locationInitialized = async (translate: TranslateService, injector: Injector, localeService: BsLocaleService) => {
  await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

  let deaultLang = 'vi';
  translate.addLangs(['en', 'vi']);
  if(localStorage.getItem('selectedLang')){
    deaultLang = localStorage.getItem('selectedLang') as string;
  } 
  translate.setDefaultLang(deaultLang);  
  localeService.use(deaultLang);
  try {
    await translate.use(deaultLang).toPromise();
  } catch (err) {
    console.log(err);
  }
  console.log(`Successfully initialized ${deaultLang} language.`);
};

const loadConfig = (
  http: HttpClient
) => {
  return new Promise((resolve, reject) => {
    //const url = ApiConstant.getApiUrl('getConfig');
    const url = ENV.env =='non-prod'?'/ext/vn-online-payment-web/config': '/config'
    http.get(url)
      .subscribe((res) => {
        console.log('config', res);
        CommonService.dataConfig = res;
        resolve(res);
      });
  });
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDatepickerModule,
    HttpClientModule,
    TranslateModule.forRoot(Constant.translateConfig),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    })
  ],
  providers: [
    CommonService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService, Injector, BsLocaleService, HttpClient],
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: appBaseHref
    },
    HttpErrorHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
