import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Button {
  title: string;
  color: string;
  class: string;
  focusInitial: boolean;
  data: any;
}

export interface DialogData {
  title: string;
  content: string;
  contentfooter: string;
  showClose: boolean;
  buttons: Button[]
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(button: Button) {
    this.dialogRef.close(button.data);
  }
}
