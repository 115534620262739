<div class="app-dialog">
  <!-- <img matSuffix *ngIf="data.showClose" src="assets/images/close.png" alt="" srcset="" mat-dialog-close> -->
  <span *ngIf="data.showClose" class="app-close-icon" mat-dialog-close></span>
  <p class="title" *ngIf="data.title">{{ data.title | translate }}</p>
  <div mat-dialog-content>
    <div [innerHTML]="data.content | translate"></div>
    <div [innerHTML]="data.contentfooter | translate"></div>
  </div>
  <div mat-dialog-actions style="justify-content: right">
    <ng-container *ngFor="let button of data.buttons; let i = index">
      <button
        (click)="onClose(button)"
        mat-flat-button
        color="{{ button.color }}"
        class="{{ button.class }} allow-tabindex"
        tabindex="{{i + 1}}"
        *ngIf="!button.focusInitial"
      >
        {{ button.title | translate }}
      </button>
      <button
        (click)="onClose(button)"
        mat-flat-button
        color="{{ button.color }}"
        class="{{ button.class }} allow-tabindex"
        tabindex="{{i + 1}}"
        cdkFocusInitial
        *ngIf="button.focusInitial"
      >
        {{ button.title | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div tabindex="{{data.buttons.length+ 1}}"></div>
