
<div class="main-navigation" (mousemove)="drawer.close()">
    <a href="https://www.manulife.com.vn/" class="navigation-logo" tabindex="-1">
        <img src="assets/images/logo.png" alt="Manulife Logo" srcset="">
    </a>
    <nav class="navigation">
        <ul class="navigation-group">
            <li class="navigation-item" *ngFor="let nav of navigations; let i = index">
                <a href="{{nav.link}}" class="navigation-item-link" tabindex="{{!dialog.openDialogs.length ? i + 1 : -1}}" (mousemove)="openMenu($event, drawer, nav)">
                    <!-- <img src="{{nav.iconSrc}}" alt="{{nav.title | translate}}" srcset=""> -->
                    <span class="nav-icon {{nav.iconSrc}}"></span>
                    <span>{{nav.title | translate}}</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<div class="main-container">
    <mat-drawer-container hasBackdrop="false">
        <mat-drawer #drawer mode="push">
            <ul class="menu">
                <li class="menu-item header">
                    <a href="/" class="menu-link header-logo">
                        <img src="assets/images/Manulife-logo.svg" alt="Manulife Logo" srcset="">
                    </a>
                </li>
                <ng-container *ngFor="let nav of navigations">
                    <ng-container *ngIf="nav.id === activeNavId && nav.items?.length">
                        <li class="menu-item" *ngFor="let item of nav.items">
                            <a href="{{item.link}}" class="menu-link">{{item.title | translate}}</a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </mat-drawer>
        <mat-drawer-content (mousemove)="drawer.close()" (scroll)="onScroll()">
            <div class="draw-content">
                <header [ngClass]="{'header-transition': isScrolled === true}">
                    <div class="mobile">
                        <a href="https://www.manulife.com.vn/" class="mobile mobile-icon">
                            <img src="assets/images/logo.png" alt="Manulife Logo" srcset="">
                        </a>
                    </div>
                    <div class="header-logo" *ngIf="!drawer.opened">
                        <img src="assets/images/Manulife-logo.svg" class="web" alt="Manulife Logo" srcset="">
                        <img src="assets/images/manulife-white.svg" class="mobile" alt="Manulife Logo" srcset="">
                    </div>
                    <div class="language-options">
                        <span [matMenuTriggerFor]="menu" tabindex="{{!dialog.openDialogs.length ? 4 : -1}}">
                            <span>{{ currentLanguage.name | translate }}</span>
                            <!-- <img src="assets/images/expand_more.svg" alt="" srcset="" class="expand-more">
                            <img src="assets/images/expand_less.svg" alt="" srcset="" class="expand-less"> -->
                            <i class="app-expand-more"></i>
                            <i class="app-expand-less"></i>
                        </span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)" [ngClass]="{'active': lang.code === currentLanguage.code}">{{ lang.name | translate }}</button>
                        </mat-menu>
                    </div>
                    <div class="mobile menu-icon" (click)="mobileMenuOpened = !mobileMenuOpened; activeNavId = 0">
                        <img src="assets/images/menu.png" alt="" srcset="" class="item" [ngClass]="{'show': !mobileMenuOpened}">
                        <span class="item app-close-white-icon" [ngClass]="{'show': mobileMenuOpened}"></span>
                    </div>
                </header>

                <div class="mobile-menu" [ngClass]="{'opened': mobileMenuOpened, 'sub-menu': subMenuOpened}">
                    <ul class="menu">
                        <li class="menu-item language-options">
                            <a class="menu-link" *ngFor="let lang of languages" [ngClass]="{'active': lang.code === currentLanguage.code}" (click)="changeLanguage(lang)">
                                <span>{{lang.name | translate}}</span>
                            </a>
                        </li>
                        <li class="menu-item" *ngFor="let nav of navigations" [ngClass]="{'active': activeNavId === nav.id || nav.id === 1}">
                            <a href="{{nav.link}}" class="menu-link" (click)="submenu(nav)">
                                <img src="{{nav.mobileIconSrc}}" alt="{{nav.title | translate}}" srcset="">
                                <span>{{nav.mobileTitle | translate}}</span>
                                <mat-icon *ngIf="nav.items?.length">chevron_right</mat-icon>
                            </a>
                        </li>
                    </ul>

                    <ul class="menu sub-menu">
                        <ng-container *ngFor="let nav of navigations">
                            <ng-container *ngIf="nav.id === activeNavId && nav.items?.length">
                                <li class="menu-item back-menu-item">
                                    <a class="menu-link" (click)="subMenuOpened = !subMenuOpened">
                                        <mat-icon>chevron_left</mat-icon>
                                        <span>{{nav.title | translate}}</span>
                                    </a>
                                </li>
                                <li class="menu-item" *ngFor="let item of nav.items">
                                    <a href="/" class="menu-link">
                                        <span>{{item.title | translate}}</span>
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
                <div class="menu-sidenav-content">
                    <router-outlet></router-outlet>
                    <app-footer></app-footer>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
<app-loading-indicator></app-loading-indicator>