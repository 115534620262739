import { Component, HostListener, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { Constant, Lang, Nav } from './constant/constant';
import { Title } from '@angular/platform-browser';
import { CommonService } from './shared/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title: string = 'vn-online-payment-web';
  showFiller: boolean = false;
  isScrolled: boolean = false;
  activeNavId: number = 0;
  navigations = Constant.navigations;
  languages = Constant.languages;
  mobileMenuOpened: boolean = false;
  subMenuOpened: boolean = false;
  currentLanguage!: Lang;
  idleTimeout: any;
  isShowingTimeout: boolean = false;
  redirectFrom!: string;
  currentRoute: any;
  lastAction: number = Date.now();
  timeout!: number;
  cwstimeout!: number;

  constructor(
    private pageTitle:Title,
    private translateService: TranslateService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private localeService: BsLocaleService
  ) { }

  @HostListener('window:click', ["$event"])
  @HostListener('window:scroll', ["$event"])
  @HostListener('mousemove', ["$event"])
  @HostListener('keypress', ["$event"])
  onEvent(event: HTMLElement) {
    this.lastAction = Date.now();
    clearTimeout(this.idleTimeout);
    this.sessionTimeout();
  }
  
  ngOnInit(): void {    
    console.log('app component config', CommonService.dataConfig);
    this.commonService.getMaintenancePage();
    this.currentLanguage = this.languages.find(lang => lang.code === this.translateService.getDefaultLang()) as Lang;  
    const namePage = this.translateService.instant("page-title");
    this.pageTitle.setTitle(namePage);
    this.timeout = parseInt(localStorage.getItem('sessionTimeout') || CommonService.dataConfig.payment_time_out);//Constant.sessionTimeout.toString());
    this.sessionTimeout();
    this.route.queryParams.subscribe((params: any) => {
      if (params.source) {
        this.redirectFrom = params.source;
      }
    });
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentRoute = event;
    });

    document.addEventListener('visibilitychange', () => {
      if (!this.isShowingTimeout && ((Date.now() - this.lastAction >= this.timeout))) {
        console.log('visibilitychange', Date.now() - this.lastAction);
        clearTimeout(this.idleTimeout);
        this.commonService.showSessionTimeoutPopup(Constant.sysRedirect.LANDING);
      }
    } , false);
    
    window.addEventListener('beforeunload', (event) => {
      if(!CommonService.isRefreshPage){
        return;
      }
      event.preventDefault();
      return event.returnValue = 'Are you sure you want to exit?';
    }, {capture: true});    
  }

  onScroll() {
    this.isScrolled = false;
    const content: any = document.getElementsByClassName('menu-sidenav-content')[0];
    const top = content.getBoundingClientRect().top;
    const scrollTop = 80;
    if (top !== scrollTop) {
      this.isScrolled = true;
    }
  }

  openMenu(event: any, menu: MatDrawer, nav: any) {
    if (nav.items?.length) {
      this.activeNavId = nav.id;
      event.stopPropagation();
      menu.open();
    }
  }

  submenu(nav: Nav) {
    if (nav.items?.length) {
      this.activeNavId = nav.id;
      this.subMenuOpened = true;
    }
  }

  async changeLanguage(lang: Lang) {
    // await this.translateService.use(lang.code);
    // this.currentLanguage = lang;
    const deaultLang = lang.code;
    this.currentLanguage = lang;
    this.translateService.setDefaultLang(deaultLang);
    localStorage.setItem('selectedLang',deaultLang);
    this.localeService.use(deaultLang);
    try {
      await this.translateService.use(deaultLang).toPromise();
    } catch (err) {
      console.log(err);
    }
    const namePage = this.translateService.instant("page-title");
    this.pageTitle.setTitle(namePage);
  }

  sessionTimeout() {
    if (this.isShowingTimeout) {
      return;
    }
    this.idleTimeout = setTimeout(() => {
      clearTimeout(this.idleTimeout);
      if(CommonService.maitenanceMode != Constant.maintenance_Mode.on){
        this.commonService.showSessionTimeoutPopup(Constant.sysRedirect.LANDING);
      }      
    }, this.timeout);
  }
}
